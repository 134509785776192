import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _643c016d = () => interopDefault(import('../pages/aboutus.vue' /* webpackChunkName: "pages/aboutus" */))
const _c66ced34 = () => interopDefault(import('../pages/cipp-offer/index.vue' /* webpackChunkName: "pages/cipp-offer/index" */))
const _ca136fa2 = () => interopDefault(import('../pages/help.vue' /* webpackChunkName: "pages/help" */))
const _deea5e94 = () => interopDefault(import('../pages/verify-email.vue' /* webpackChunkName: "pages/verify-email" */))
const _4d422136 = () => interopDefault(import('../pages/business/analytics.vue' /* webpackChunkName: "pages/business/analytics" */))
const _1c1f0035 = () => interopDefault(import('../pages/business/communication.vue' /* webpackChunkName: "pages/business/communication" */))
const _468cbcd4 = () => interopDefault(import('../pages/business/membersupport.vue' /* webpackChunkName: "pages/business/membersupport" */))
const _003cfe46 = () => interopDefault(import('../pages/dashboard/combine.vue' /* webpackChunkName: "pages/dashboard/combine" */))
const _21662f7f = () => interopDefault(import('../pages/dashboard/documents.vue' /* webpackChunkName: "pages/dashboard/documents" */))
const _44660f1e = () => interopDefault(import('../pages/dashboard/landing.vue' /* webpackChunkName: "pages/dashboard/landing" */))
const _14edf6db = () => interopDefault(import('../pages/dashboard/nextsteps.vue' /* webpackChunkName: "pages/dashboard/nextsteps" */))
const _21b94dfd = () => interopDefault(import('../pages/dashboard/report.vue' /* webpackChunkName: "pages/dashboard/report" */))
const _191c12c6 = () => interopDefault(import('../pages/dashboard/reports.vue' /* webpackChunkName: "pages/dashboard/reports" */))
const _488fa47c = () => interopDefault(import('../pages/dashboard/retirement.vue' /* webpackChunkName: "pages/dashboard/retirement" */))
const _08ba44c6 = () => interopDefault(import('../pages/dashboard/review-choices.vue' /* webpackChunkName: "pages/dashboard/review-choices" */))
const _2bde3f36 = () => interopDefault(import('../pages/dashboard/selected-funds.vue' /* webpackChunkName: "pages/dashboard/selected-funds" */))
const _2df52372 = () => interopDefault(import('../pages/dashboard/selfhelpdirectory.vue' /* webpackChunkName: "pages/dashboard/selfhelpdirectory" */))
const _591545a8 = () => interopDefault(import('../pages/dashboard/settings.vue' /* webpackChunkName: "pages/dashboard/settings" */))
const _2b8999e2 = () => interopDefault(import('../pages/dashboard/shortlist-funds.vue' /* webpackChunkName: "pages/dashboard/shortlist-funds" */))
const _41ce2ec5 = () => interopDefault(import('../pages/onboarding/annuity-options.vue' /* webpackChunkName: "pages/onboarding/annuity-options" */))
const _95c0811a = () => interopDefault(import('../pages/onboarding/authorization.vue' /* webpackChunkName: "pages/onboarding/authorization" */))
const _53755288 = () => interopDefault(import('../pages/onboarding/checkout.vue' /* webpackChunkName: "pages/onboarding/checkout" */))
const _46caf969 = () => interopDefault(import('../pages/onboarding/employment-info.vue' /* webpackChunkName: "pages/onboarding/employment-info" */))
const _e5b1d212 = () => interopDefault(import('../pages/onboarding/final-salary.vue' /* webpackChunkName: "pages/onboarding/final-salary" */))
const _883d3f0c = () => interopDefault(import('../pages/onboarding/fund-selection.vue' /* webpackChunkName: "pages/onboarding/fund-selection" */))
const _07ba4f3a = () => interopDefault(import('../pages/onboarding/login.vue' /* webpackChunkName: "pages/onboarding/login" */))
const _00a9849e = () => interopDefault(import('../pages/onboarding/login-error.vue' /* webpackChunkName: "pages/onboarding/login-error" */))
const _afee2cf2 = () => interopDefault(import('../pages/onboarding/other-pot-addition.vue' /* webpackChunkName: "pages/onboarding/other-pot-addition" */))
const _d68a0786 = () => interopDefault(import('../pages/onboarding/pension-details.vue' /* webpackChunkName: "pages/onboarding/pension-details" */))
const _384b857a = () => interopDefault(import('../pages/onboarding/pension-provider-by-name.vue' /* webpackChunkName: "pages/onboarding/pension-provider-by-name" */))
const _cd16f3d6 = () => interopDefault(import('../pages/onboarding/personal-info.vue' /* webpackChunkName: "pages/onboarding/personal-info" */))
const _31c386f3 = () => interopDefault(import('../pages/onboarding/redeem-voucher.vue' /* webpackChunkName: "pages/onboarding/redeem-voucher" */))
const _1f3212cf = () => interopDefault(import('../pages/onboarding/reset-password.vue' /* webpackChunkName: "pages/onboarding/reset-password" */))
const _23cb1b7d = () => interopDefault(import('../pages/onboarding/success.vue' /* webpackChunkName: "pages/onboarding/success" */))
const _529e549e = () => interopDefault(import('../pages/onboarding/suez-info.vue' /* webpackChunkName: "pages/onboarding/suez-info" */))
const _3cec3ecc = () => interopDefault(import('../pages/personal/account.vue' /* webpackChunkName: "pages/personal/account" */))
const _78aa8b73 = () => interopDefault(import('../pages/personal/nextsteps.vue' /* webpackChunkName: "pages/personal/nextsteps" */))
const _20e3a7ac = () => interopDefault(import('../pages/personal/retirement.vue' /* webpackChunkName: "pages/personal/retirement" */))
const _ae2fad6a = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _48fcae54 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aboutus",
    component: _643c016d,
    name: "aboutus"
  }, {
    path: "/cipp-offer",
    component: _c66ced34,
    name: "cipp-offer"
  }, {
    path: "/help",
    component: _ca136fa2,
    name: "help"
  }, {
    path: "/verify-email",
    component: _deea5e94,
    name: "verify-email"
  }, {
    path: "/business/analytics",
    component: _4d422136,
    name: "business-analytics"
  }, {
    path: "/business/communication",
    component: _1c1f0035,
    name: "business-communication"
  }, {
    path: "/business/membersupport",
    component: _468cbcd4,
    name: "business-membersupport"
  }, {
    path: "/dashboard/combine",
    component: _003cfe46,
    name: "dashboard-combine"
  }, {
    path: "/dashboard/documents",
    component: _21662f7f,
    name: "dashboard-documents"
  }, {
    path: "/dashboard/landing",
    component: _44660f1e,
    name: "dashboard-landing"
  }, {
    path: "/dashboard/nextsteps",
    component: _14edf6db,
    name: "dashboard-nextsteps"
  }, {
    path: "/dashboard/report",
    component: _21b94dfd,
    name: "dashboard-report"
  }, {
    path: "/dashboard/reports",
    component: _191c12c6,
    name: "dashboard-reports"
  }, {
    path: "/dashboard/retirement",
    component: _488fa47c,
    name: "dashboard-retirement"
  }, {
    path: "/dashboard/review-choices",
    component: _08ba44c6,
    name: "dashboard-review-choices"
  }, {
    path: "/dashboard/selected-funds",
    component: _2bde3f36,
    name: "dashboard-selected-funds"
  }, {
    path: "/dashboard/selfhelpdirectory",
    component: _2df52372,
    name: "dashboard-selfhelpdirectory"
  }, {
    path: "/dashboard/settings",
    component: _591545a8,
    name: "dashboard-settings"
  }, {
    path: "/dashboard/shortlist-funds",
    component: _2b8999e2,
    name: "dashboard-shortlist-funds"
  }, {
    path: "/onboarding/annuity-options",
    component: _41ce2ec5,
    name: "onboarding-annuity-options"
  }, {
    path: "/onboarding/authorization",
    component: _95c0811a,
    name: "onboarding-authorization"
  }, {
    path: "/onboarding/checkout",
    component: _53755288,
    name: "onboarding-checkout"
  }, {
    path: "/onboarding/employment-info",
    component: _46caf969,
    name: "onboarding-employment-info"
  }, {
    path: "/onboarding/final-salary",
    component: _e5b1d212,
    name: "onboarding-final-salary"
  }, {
    path: "/onboarding/fund-selection",
    component: _883d3f0c,
    name: "onboarding-fund-selection"
  }, {
    path: "/onboarding/login",
    component: _07ba4f3a,
    name: "onboarding-login"
  }, {
    path: "/onboarding/login-error",
    component: _00a9849e,
    name: "onboarding-login-error"
  }, {
    path: "/onboarding/other-pot-addition",
    component: _afee2cf2,
    name: "onboarding-other-pot-addition"
  }, {
    path: "/onboarding/pension-details",
    component: _d68a0786,
    name: "onboarding-pension-details"
  }, {
    path: "/onboarding/pension-provider-by-name",
    component: _384b857a,
    name: "onboarding-pension-provider-by-name"
  }, {
    path: "/onboarding/personal-info",
    component: _cd16f3d6,
    name: "onboarding-personal-info"
  }, {
    path: "/onboarding/redeem-voucher",
    component: _31c386f3,
    name: "onboarding-redeem-voucher"
  }, {
    path: "/onboarding/reset-password",
    component: _1f3212cf,
    name: "onboarding-reset-password"
  }, {
    path: "/onboarding/success",
    component: _23cb1b7d,
    name: "onboarding-success"
  }, {
    path: "/onboarding/suez-info",
    component: _529e549e,
    name: "onboarding-suez-info"
  }, {
    path: "/personal/account",
    component: _3cec3ecc,
    name: "personal-account"
  }, {
    path: "/personal/nextsteps",
    component: _78aa8b73,
    name: "personal-nextsteps"
  }, {
    path: "/personal/retirement",
    component: _20e3a7ac,
    name: "personal-retirement"
  }, {
    path: "/blog/:slug?",
    component: _ae2fad6a,
    name: "blog-slug"
  }, {
    path: "/",
    component: _48fcae54,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
